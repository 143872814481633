<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title>Création d'un comité d'entreprise</v-card-title>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="6"
            md="6"
          >
            <h4 class="mb-5">
              Informations entreprise
            </h4>

            <v-text-field
              v-model="entityData.company"
              :rules="companyRules"
              class="mb-5"
              label="Nom de l'entreprise"
              outlined
              dense
              placeholder="Nom de l'entreprise"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.address"
              class="mb-5"
              label="Adresse"
              outlined
              dense
              placeholder="Adresse"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.zipCode"
              class="mb-5"
              label="Code postal"
              outlined
              dense
              placeholder="Code postal"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.city"
              class="mb-5"
              label="Ville"
              outlined
              dense
              placeholder="Ville"
              hide-details
            ></v-text-field>

            <v-select
              v-model="entityData.country"
              :items="countries"
              class="mb-3"
              label="Pays"
              outlined
            ></v-select>

            <h4 class="mb-5">
              Identifiants
            </h4>

            <v-text-field
              v-model="entityData.login"
              :rules="loginRules"
              class="mb-5"
              label="Utilisateur"
              outlined
              dense
              placeholder="Utilisateur"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.password"
              :rules="passwordRules"
              class="mb-5"
              label="Mot de passe"
              outlined
              dense
              placeholder="Mot de passe"
              hide-details
            ></v-text-field>

            <v-switch
              v-model="entityData.enabled"
              :label="'Etat : ' + (entityData.enabled === true ? 'activé' : 'désactivé')"
            ></v-switch>

            <h4>Sélection du moteur</h4>
            <br>
            <v-select
              v-model="engineSelection"
              :items="engines"
              outlined
              chips
              persistent-hint
            ></v-select>
          </v-col>
          <v-col
            cols="6"
            md="6"
          >
            <h4 class="mb-5">
              Contact
            </h4>

            <v-select
              v-model="entityData.title"
              :items="titles"
              class="mb-3"
              label="Civilité"
              outlined
            ></v-select>

            <v-text-field
              v-model="entityData.lastName"
              class="mb-5"
              label="Nom"
              outlined
              dense
              placeholder="Nom"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.firstName"
              class="mb-5"
              label="Prénom"
              outlined
              dense
              placeholder="Prénom"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.job"
              class="mb-5"
              label="Fonction"
              outlined
              dense
              placeholder="Fonction"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.email"
              class="mb-5"
              label="Email"
              outlined
              dense
              placeholder="Email"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.phone"
              class="mb-5"
              label="Tél"
              outlined
              dense
              placeholder="Tél"
              hide-details
            ></v-text-field>

            <v-textarea
              v-model="entityData.comment"
              class="mb-5"
              label="Commentaire"
              outlined
              dense
              placeholder="Commentaire"
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'worksCouncilList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import config from '../../config'
import router from '@/router'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    Snackbar,
    Loader
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      engines: [],
      engineSelection: [],
      titles: ['M.', 'Mme'],
      countries: [
        'France',
        'Allemagne',
        'Italie',
        'Espagne',
        'Royaume-Unis',
        'Irlande',
        'Portugal',
        'Belgique',
        'Luxembourg',
        'Pays-Bas',
        'Suisse',
        'Danemark',
        'Norvège',
        'Suède',
        'Finlande',
        'Estonie',
        'Lettonie',
        'Lituanie',
        'Pologne',
        'République Tchèque',
        'Liechtenstein',
        'Autriche',
        'Slovaquie',
        'Hongrie',
        'Slovénie',
        'Andorre',
        'Monaco',
        'Saint-Marin',
        'Turquie',
        'Vatican',
        'Croatie',
        'Bosnie-Herzégovine',
        'Malte',
        'Roumanie',
        'Serbie',
        'Bulgarie',
        'Albanie',
        'Macédoine',
        'Grèce',
        'Moldavie',
        'Ukraine',
        'Biélorussie',
        'Russie',
        'Géorgie',
        'Azerbaïdjan',
        'Arménie',
        'Islande',
        'Monténégro'
      ],
      entityData: {
        enabled: false,
        address: null,
        zipCode: null,
        city: null,
        country: null,
        title: null,
        lastName: null,
        firstName: null,
        job: null,
        email: null,
        phone: null,
        comment: null,
        engine: null,
        company: null,
        login: null,
        password: null
      },
      companyRules: [
        v => !!v || 'Le nom de l\'entreprise est obligatoire'
      ],
      loginRules: [
        v => !!v || 'Le login est obligatoire'
      ],
      passwordRules: [
        v => !!v || 'Le mot de passe est obligatoire'
      ],
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    }
  },
  created () {
    this.getEngines()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.postWorksCouncil()
      }
    },
    getEngines () {
      this.loading = true

      const params = {
        limit: 150
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/engines', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const engines = response.data

            for (let i = 0; i < engines.length; i++) {
              const currentEngine = engines[i]

              this.engines.push({
                text: currentEngine.name,
                value: currentEngine.id
              })
            }
          }

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    postWorksCouncil () {
      this.valid = false
      this.loading = true

      if (this.engineSelection.length) {
        this.entityData.engine = {
          id: this.engineSelection
        }
      }

      Vue.prototype.$http
        .post(config.apiUrl + '/api/works-councils', this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 201) {
            const worksCouncil = response.data

            this.errors = []
            this.snackbar = true
            this.successMessage = 'Comité d\'entreprise créé avec succès.'

            setTimeout(() => {
              router.push({ name: 'worksCouncilDetails', params: { worksCouncilId: worksCouncil.id } })
            }, 1500)
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    }
  }
}
</script>
